import React, { useState } from "react";
import {
  MdAdd,
  MdRemove,

} from "react-icons/md";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";



const FaqAccordion = ({ setFaq, array=[]  }) => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    console.log(open === value)
    setFaq(open === value ? 0 : value);
    setOpen(()=>open === value ? 0 : value);
  };



  return (
    <div className="space-y-4">
      {array.map((item) => (
        <div key={item.id} className="flex items-start gap-2 md:gap-6">
          <div
            className={`${
              open === item.id
                ? "bg-gradient-to-tr from-hotPink via-purplish to-accent"
                : "dark:bg-jacarta-800 bg-gray-300"
            } p-4 rounded-lg mt-[15px]`}
          >
            <span className="cursor-pointer text-white">
              {React.cloneElement(item.icon, {
                className: "w-[20px] md:w-[30px] h-[20px] md:h-[30px]"
              })}
            </span>
          </div>

          <div className="flex-1">
            <Accordion
              open={open === item.id}
              icon={
                open === item.id ? (
                  <MdRemove className="h-[30px] w-[30px] transition-transform cursor-pointer" />
                ) : (
                  <MdAdd className="h-[30px] w-[30px] transition-transform cursor-pointer" />
                )
              }
            >
              <AccordionHeader
                onClick={() => handleOpen(item.id)}
                className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white text-[20px] md:text-[24px]"
              >
                {item.title}
              </AccordionHeader>
              <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 text-base">
                {item.content}
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqAccordion;
