import React from 'react'

function TLDs({ activeAccordion }) {
  const [isWeb3Hover, setIsWeb3Hover] = React.useState(false);
  const [isDomainHovered, setIsDomainHovered] = React.useState(false);
  const [isTldHovered, setIsTldHovered] = React.useState(false);
  const [isNameHovered, setIsNameHovered] = React.useState(false);

  // Update effect to handle accordion changes
  React.useEffect(() => {
    if (activeAccordion === 1) {
      setIsTldHovered(()=>true);
      setIsNameHovered(()=>false);
      setIsWeb3Hover(()=>false);

    } else if (activeAccordion === 2) {
      setIsTldHovered(()=>false);
      setIsNameHovered(()=>true);
      setIsWeb3Hover(()=>false);
    } else if (activeAccordion === 3) {
      setIsTldHovered(()=>false);
      setIsNameHovered(()=>false);
      setIsWeb3Hover(()=>true);
    } else {
      setIsTldHovered(()=>false);
      setIsNameHovered(()=>false);
      setIsWeb3Hover(()=>false);
    }
  }, [activeAccordion]);

  return (
    <div>
      <div className="mb-10 max-w-[700px] mx-auto w-full">
        <div className="relative w-full max-w-[400px] mx-auto py-10">
          <div className="my-16">
            <div className="group/domain relative flex items-center justify-center">
              {/* Default text when nothing is hovered */}
              <div
                className={`absolute w-full -bottom-8 text-center transition-opacity ${
                  isNameHovered || isTldHovered 
                    ? "opacity-0" 
                    : "opacity-100"
                }`}
              >
                <div
                  className="flex items-center justify-center gap-2 group"
                  onMouseEnter={() => setIsWeb3Hover(true)}
                  onMouseLeave={() => setIsWeb3Hover(false)}
                >
                  <div
                    className={`h-[1px] w-28 transition-colors ${
                      isWeb3Hover || activeAccordion === 1 || activeAccordion === 3 
                        ? "bg-accent" 
                        : "bg-gray-400"
                    }`}
                  ></div>
                  <span
                    className={`text-sm cursor-pointer transition-colors ${
                      isWeb3Hover || activeAccordion === 1 || activeAccordion === 3 
                        ? "text-accent" 
                        : ""
                    }`}
                  >
                   Web3 Domain
                  </span>
                  <div
                    className={`h-[1px] w-28 transition-colors ${
                      isWeb3Hover || activeAccordion === 1 || activeAccordion === 3 
                        ? "bg-accent" 
                        : "bg-gray-400"
                    }`}
                  ></div>
                </div>
              </div>

              {/* NAME part */}
              <div
                className="relative cursor-pointer group/name"
                onMouseEnter={() => setIsNameHovered(true)}
                onMouseLeave={() => setIsNameHovered(false)}
              >
                <span
                  className={`text-7xl font-bold transition-colors ${
                    isWeb3Hover ||  activeAccordion === 3 
                      ? "text-accent" 
                      : ""
                  } ${
                    isNameHovered || activeAccordion === 2 
                      ? "text-accent" 
                      : ""
                  }`}
                >
                  NAME
                </span>
                {/* Downward Bracket lines for NAME */}
                <div
                  className={`absolute -bottom-6 left-0 w-full transition-all ${
                    isNameHovered || activeAccordion === 2 
                      ? "opacity-100" 
                      : "opacity-0 group-hover/name:opacity-100"
                  }`}
                >
                  <div className="relative h-4 w-full">
                    <div className="absolute left-0 h-full w-[50%] border-l-2 border-b-2 border-accent"></div>
                    <div className="absolute right-0 h-full w-[50%] border-r-2 border-b-2 border-accent"></div>
                    <div className="absolute left-1/2 translate-y-[15px] w-[2px] h-6 bg-accent"></div>
                    <div className="absolute -bottom-12 w-full text-center">
                        <span className="text-base">
                          Sub-Level Domain
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* TLD part */}
              <div
                className="relative cursor-pointer group/tld"
                onMouseEnter={() => setIsTldHovered(true)}
                onMouseLeave={() => setIsTldHovered(false)}
              >
                <span
                  className={`text-7xl font-bold text-gray-400 transition-colors ${
                    isWeb3Hover || activeAccordion === 1 || activeAccordion === 3 
                      ? "!text-accent" 
                      : ""
                  } ${
                    isTldHovered || activeAccordion === 1 
                      ? "!text-accent" 
                      : ""
                  }`}
                >
                  .TLD
                </span>
                {/* Upward Bracket lines for TLD */}
                <div
                  className={`absolute -top-6 left-0 w-full transition-all ${
                    isTldHovered || activeAccordion === 1 
                      ? "opacity-100" 
                      : "opacity-0 group-hover/tld:opacity-100"
                  }`}
                >
                  <div className="relative h-4 w-full">
                    <div className="absolute left-0 h-full w-[50%] border-l-2 border-t-2 border-accent"></div>
                    <div className="absolute right-0 h-full w-[50%] border-r-2 border-t-2 border-accent"></div>
                    <div className="absolute left-1/2 w-[2px] h-6 bg-accent -top-6"></div>
                    <div className="absolute -top-12 w-full text-center">
                      <span className="text-base">Top-Level Domain</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TLDs