import React from "react";
import TLDs from "./TLDs";
import { Fade } from "react-reveal";
import FaqAccordion from "../../../components/domains/faq/accoedion";
import Link from "next/link";
import { MdPermIdentity, MdSyncAlt } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";

function TLDsld({ NoBorder }) {
  const [faq, setFaq] = React.useState(1);
  const arrayData = [
    {
      id: 1,
      icon: <MdPermIdentity />,
      title: "Top-Level Domains (TLDs)",
      content:
        "As a TLD owner, you control a branded blockchain presence (brand), set pricing, and sell unlimited sub-level domains (SLDs) to your users. You can also launch an Al agent and custom token under your TLD to enhance engagement and value.",
    },

    {
      id: 2,
      icon: <MdSyncAlt />,
      title: "Sub-Level Domains (SLDs)",
      content:
        "When users buy an SLD (user.brandx), they get a branded blockchain identity that they fully own as an NFT, along with access to the brand's Web3 community.",
    },
    {
      id: 3,
      icon: <BsCurrencyDollar />,
      title: "Web3 Domains",
      content:
        "When a Web3 domain is purchased, the brand earns revenue while seamlessly onboarding users. Buyers receive a crypto wallet via email generation, a blockchain identity (NFT), and a branded wallet where they can receive future offers, NFTs, and token drops—creating a continuous Web3 experience with zero code required.",
    },
  ];
  return (
    <div id='web3' className="container relative py-12  md:py-[98px] lg:py-[118px] ">
      <p className="text-center text-[18px] mb-12 md:mb-20 max-w-[600px] mx-auto w-full">
        A Web3 domain is a blockchain identity that connects brands and users,
        enabling ownership, engagement, and new revenue opportunities.
      </p>

      <Fade ssrReveal right delay={350}>
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <div>
            <FaqAccordion setFaq={setFaq} array={arrayData}/>
          </div>
          <div>
            <TLDs activeAccordion={faq} />
          </div>
        </div>
      </Fade>
      <Fade ssrReveal bottom delay={350}>
        <div className="flex items-center justify-center gap-8 mt-5 md:mt-20">
          <Link
            href="/domains"
            className="min-w-[200px] xl:min-w-[230px] items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center w-full md:w-[20%]"
          >
            Start now
          </Link>
          <Link
            href="/community"
            className={`${
              NoBorder ? "hidden" : "hidden lg:flex"
            } items-center  justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300  w-full md:w-[20%]`}
          >
            Learn more
          </Link>
        </div>
      </Fade>
    </div>
  );
}

export default TLDsld;
